var Queue = function()
{
    this.queue = [];
    this.running = false;
    this.paused = true;
    this.waitingForPause = false;
    this.delaySpeed = 100;
};

Queue.prototype.add = function(callback)
{
    var _this = this;

    this.queue.push(function()
    {
        var finished = callback();

        if (typeof finished === "undefined" || finished)
        {
            _this.dequeue();
        }
    });

    /*
     if (this.running)
     {
     this.dequeue();
     }
     */

    return this;
};

Queue.prototype.pause = function()
{
    this.running = false;
    this.waitingForPause = true;
};

Queue.prototype.start = function()
{
    this.running = true;

    this.dequeue();
};

Queue.prototype.delay = function(time)
{
    var _this = this;
    var time = typeof time !== 'undefined' ? time : _this.delaySpeed;

    this.queue.push(function()
    {
        return setTimeout(function()
        {
            _this.dequeue();
        }, time);
    });
};

Queue.prototype.dequeue = function()
{
    var shift = this.queue.shift();

    if (shift)
    {
        if (!this.running)
        {
            this.waitingForPause = false;
            this.paused = true;
            this.queue.unshift(shift);
        }
        else
        {
        	this.paused = false;

            return shift();
        }
    }
    else
    {
	    this.paused = true;
    }

    //return shift;
};

Queue.prototype.next = Queue.prototype.dequeue;