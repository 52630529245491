// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());

(function( $ ){
    $.preload = function()
    {
        var imgs = Object.prototype.toString.call( arguments[ 0 ]) === '[object Array]'
            ? arguments[ 0 ] : arguments;

        var tmp = [];
        var i   = imgs.length;

        for( ; i-- ; ) tmp.push( $( '<img />' ).attr( 'src', imgs[ i ]));
    };
})(jQuery);

(function ($, window, document, undefined)
{
    $.ajaxHandler = function(params, options, settings)
    {
        var deferred = $.Deferred();
	    var errorOptions = {};

	    // default params
	    var settingsDefault = {
		    'skipCsrf' : false
	    };

	    var optionsDefault = {
		    'timeout' : 8000
	    };

	    // variables
	    settings = (typeof settings != 'undefined') ? settings : {};
        options = (typeof options != 'undefined') ? options : {};

	    if (options['sourceElement'])
		    errorOptions['sourceElement'] = options['sourceElement'];

	    // might want to skip sending special headers sometimes?
	    if (!settingsDefault['skipCsrf'])
	    {
		    params['headers'] = {
			    'X-CSRF-TOKEN' : $('meta[name="csrf-token"]').attr('content')
		    };
	    }

        params = $.extend(optionsDefault, params);

        var jqxhr = $.ajax(params);

	    jqxhr.fail(function(jqXHR, textStatus)
	    {
		    if (textStatus === "timeout")
		    {
			    main.showMessageBox(
				    {
					    'Text' : $.i18n._('error_request_timeout')
				    },
				    'general/error-box',
				    options
			    );

			    deferred.reject();
		    }
	    });

        jqxhr.done(function(data, status, xhr)
        {
            if (data['error'])
            {
                main.showErrorMessage(data['error'], errorOptions);

                deferred.reject();
            }
            else
            {
                deferred.resolve(data, status, xhr);
            }
        });

        return deferred.promise();
    };

    $.fn.setFilterButtonSelection = function(options)
    {
	    options = $.extend({
		    'Siblings' : false,
		    'SingleSibling' : false
	    }, options);

        var $_this = $(this),
            filter = $_this.data('filter'),
	        $_siblings = (options['Siblings']) ? $_this.closest(options['Siblings']).find('.c-filter-button') : $_this.siblings();

        if (filter === 'all')
        {
	        $_siblings.removeClass('-c-sel');
	        $_this.addClass('-c-sel');
        }
        else
        {
            if ($_this.hasClass('-c-sel'))
            {
	            $_this.removeClass('-c-sel');

	            if (!$_siblings.filter('.-c-sel').length) {
		            $_siblings.filter('.js-filter-all').addClass('-c-sel');
	            }
            }
            else
            {
	            if (options['SingleSibling']) {
		            $_siblings.filter('.-c-sel').removeClass('-c-sel');
	            }

	            $_this.addClass('-c-sel');

	            $_siblings.filter('.js-filter-all').removeClass('-c-sel');
            }
        }
    };

    $.fn.changeCardSize = function(options)
    {
        var settings = $.extend({
            size: 'large',
            addClass: 'l',
            replaceClass: false,
            card: false
        }, options);

        return this.each(function()
        {
            var $_this = $(this),
                cardObj = settings.card || $_this.data('card');

            if (cardObj == null || typeof cardObj[settings.size] == 'undefined')
                return false;

            $_this.find('.c-card__pic img').attr('src', function(i, imgPath)
            {
                // set new extension
                imgPath = imgPath.substr(0, imgPath.lastIndexOf('.')) + cardObj[settings.size];

                // change to new size
                var regex = new RegExp("/(small|medium|large)/");

                return imgPath.replace(regex, "/" + settings.size + "/");
            });

            /*
            // set new extension
            imgPath = imgPath.substr(0, imgPath.lastIndexOf('.')) + cardObj[settings.size];

            // split image src and and reverse array for easier editing
            var imgPathSplit = imgPath.split('/').reverse();

            // replace size path
            imgPathSplit[1] = settings.size;

            var imgPathNew = imgPathSplit.reverse().join('/');

            $_this.find('.pic img').attr('src', imgPathNew);
            */

            /*
            if (settings.replaceClass)
            {
                $_this.removeClass(function (index, css) {
                    return (css.match(/(^|\s)card-\S+/g) || []).join(' ');
                });
            }
            */

            $_this.removeClass('-size-s -size-m -size-l').addClass('-size-' + settings.addClass);
        });
    };

})(jQuery, window, document);

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getPathFromUrl(url) {
    return url.split(/[?#]/)[0];
}

function vsprintf(format, args) {
    return this.sprintf.apply(this, [format].concat(args));
}

/*
String.prototype.replaceLast = function (what, replacement) {
    return this.reverse().replace(new RegExp(what.reverse()), replacement.reverse()).reverse();
};
*/
