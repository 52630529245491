(function(HistoryManager, $, undefined)
{
    var stack,
        fromPopState = false,
        skipStack = false;

    HistoryManager.init = function()
    {
        this.stack = [];

        window.addEventListener('popstate', function(e)
        {
            // old browsers (safari) might trigger a popstate on page load, so dont do anything if no state object or if it's a hash #
            if (!e.state)
                return false;

            if (!HistoryManager.skipStack)
            {
                if (HistoryManager.stack.length)
                {
                    HistoryManager.fromPopState = true;

                    if (HistoryManager.stack.length > 0)
                    {
                        var curPageCallback = HistoryManager.stack.pop();
                        var curPageCallbackOptions = curPageCallback[1].call();

                        // check if there are any options to do before running the previous page's return-to-page functions
                        if (typeof curPageCallbackOptions != 'undefined')
                        {
                            // do not run previous page's return-to-page functions
                            if (curPageCallbackOptions['SkipBackFunctions'])
                            {
                                HistoryManager.fromPopState = false;
                                //HistoryManager.removeLastState();

                                return false;
                            }
                        }

                        if (HistoryManager.stack.length > 0)
                        {
                            var prevPageCallback = HistoryManager.stack[HistoryManager.stack.length-1][0];

                            prevPageCallback.call();
                        }
                        else
                        {
                            window.history.go(-1);
                        }
                    }
                }
            }

            HistoryManager.skipStack = false;
        });
    };

    HistoryManager.pushState = function(url, initCallback, popCallback)
    {
        if (supportsPushState())
        {
            if (!this.fromPopState)
            {
                if (typeof initCallback !== 'undefined' || typeof popCallback !== 'undefined')
                {
                    this.stack.push([initCallback, popCallback]);
                }

                window.history.pushState(url, null, url);

                return true;
            }

            this.fromPopState = false;
        }
    };

    HistoryManager.replaceState = function(url, initCallback, popCallback)
    {
        if (supportsPushState())
        {
            if (!this.fromPopState)
            {
                if (typeof initCallback !== 'undefined' || typeof popCallback !== 'undefined')
                {
                    this.stack.push([initCallback, popCallback]);
                }

                window.history.replaceState(url, null, url);

                return true;
            }

            this.fromPopState = false;
        }
    };

    HistoryManager.removeLastState = function()
    {
        if (HistoryManager.stack.length > 0)
        {
            HistoryManager.stack.pop();
        }
    };

    HistoryManager.goBack = function(steps)
    {
        steps = (typeof steps !== 'undefined') ? steps : -1;

        if (supportsPushState())
        {
            HistoryManager.removeLastState();
            HistoryManager.skipStack = true;
            window.history.go(steps);
        }
    };

    function supportsPushState()
    {
        return ('pushState' in window.history) && window.history['pushState'] !== null;
    }

}(window.HistoryManager = window.HistoryManager || {}, jQuery));

$(function()
{
	HistoryManager.init();
});