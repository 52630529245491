(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['battle/active-ninja'] = template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "-border-"
    + container.escapeExpression(((helper = (helper = helpers.Border || (depth0 != null ? depth0.Border : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Border","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "-c-"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.Card : depth0)) != null ? stack1.css : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.Card : depth0)) != null ? stack1.pic : stack1),true,{"name":"ifCond","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "						<img src=\""
    + alias4(((helper = (helper = helpers.CardPath || (depth0 != null ? depth0.CardPath : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CardPath","hash":{},"data":data}) : helper)))
    + "large/"
    + alias4(((helper = (helper = helpers.Picture || (depth0 != null ? depth0.Picture : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Picture","hash":{},"data":data}) : helper)))
    + "_b"
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.Card : depth0)) != null ? stack1.large : stack1), depth0))
    + "\">\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "						<img src=\"/img/gfx/themes/akatsuki-dawn/shared/cards/large/"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.Card : depth0)) != null ? stack1.pic : stack1), depth0))
    + "\">\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return "\n			<div class=\"c-card__ele  c-ele -ele-"
    + container.escapeExpression(((helper = (helper = helpers.Element || (depth0 != null ? depth0.Element : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Element","hash":{},"data":data}) : helper)))
    + "\"></div>\n\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "\n			<div class=\"c-card__lvl\">\n				<span class=\"c-card__lvl-text\">"
    + alias3((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"card_level",{"name":"i18n","hash":{},"data":data}))
    + "</span>\n				<span class=\"c-card__lvl-nr\">"
    + alias3(((helper = (helper = helpers.Level || (depth0 != null ? depth0.Level : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"Level","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n\n";
},"14":function(container,depth0,helpers,partials,data) {
    return " -c-inactive";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n			<div class=\"c-item -size-btn  "
    + alias4(((helper = (helper = helpers.Color || (depth0 != null ? depth0.Color : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Color","hash":{},"data":data}) : helper)))
    + "  js-item-tooltip\" data-url=\"/tooltip/"
    + ((stack1 = (helpers.ifElse || (depth0 && depth0.ifElse) || alias2).call(alias1,(depth0 != null ? depth0.Path : depth0),"item",{"name":"ifElse","hash":{},"data":data})) != null ? stack1 : "")
    + "/"
    + alias4(((helper = (helper = helpers.Identifier || (depth0 != null ? depth0.Identifier : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Identifier","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Options : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n				<div class=\"c-item__icon  a-item-icon\"></div>\n			</div>\n\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper;

  return "?Options="
    + container.escapeExpression(((helper = (helper = helpers.Options || (depth0 != null ? depth0.Options : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Options","hash":{},"data":data}) : helper)));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "<div class=\"m-card-container\" data-ninjaid=\""
    + alias4(((helper = (helper = helpers.NinjaID || (depth0 != null ? depth0.NinjaID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NinjaID","hash":{},"data":data}) : helper)))
    + "\" data-index=\""
    + alias4(((helper = (helper = helpers.LineupIndex || (depth0 != null ? depth0.LineupIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LineupIndex","hash":{},"data":data}) : helper)))
    + "\">\n\n	<div class=\"c-card -size-l "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Border : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n\n		<div class=\"c-card__pic "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Card : depth0)) != null ? stack1.css : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n			<div class=\"c-card__pic-inner\">\n				<img src=\""
    + alias4(((helper = (helper = helpers.CardPath || (depth0 != null ? depth0.CardPath : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CardPath","hash":{},"data":data}) : helper)))
    + "large/"
    + alias4(((helper = (helper = helpers.Picture || (depth0 != null ? depth0.Picture : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Picture","hash":{},"data":data}) : helper)))
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.Card : depth0)) != null ? stack1.large : stack1), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Card : depth0)) != null ? stack1.pic : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</div>\n		</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Element : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Level : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n		<div class=\"c-card__stats\">\n\n			<div class=\"c-card__stats-empty\"></div>\n\n			<div class=\"c-card__buki "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.Bukijutsu : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  c-bar -type-bukijutsu -border-hidden\" style=\"width: "
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.Bukijutsu : depth0)) != null ? stack1.Width : stack1), depth0))
    + "%;\">\n\n				<div class=\"c-bar__fill\" style=\"width: 100%;\"></div>\n\n				<div class=\"c-bar__text\">\n					<span class=\"c-bar__text-cur\">"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.Bukijutsu : depth0)) != null ? stack1.Cur : stack1), depth0))
    + "</span>\n				</div>\n\n				<div class=\"c-bar__desc\">\n					<span>"
    + alias4((helpers.i18n || (depth0 && depth0.i18n) || alias2).call(alias1,"card_bukijutsu",{"name":"i18n","hash":{},"data":data}))
    + "</span>\n				</div>\n\n			</div>\n\n		</div>\n\n		<div class=\"c-card__data\">\n\n			<div class=\"c-card__seal\">\n				<span>"
    + alias4(((helper = (helper = helpers.Seal || (depth0 != null ? depth0.Seal : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Seal","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n\n			<div class=\"c-card__chakra\">\n				<span>"
    + alias4(((helper = (helper = helpers.Chakra || (depth0 != null ? depth0.Chakra : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Chakra","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n\n			<div class=\"c-card__dmg\"></div>\n\n		</div>\n\n	</div>\n\n	<div class=\"m-card-container__items\">\n\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.Equipment : depth0),{"name":"each","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n	</div>\n\n</div>";
},"useData":true});
templates['battle/attack-box'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n			<div class=\"pm-battle-attack__dmg-modifier\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Modifier : depth0)) != null ? stack1.Reroll : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Modifier : depth0)) != null ? stack1.Crit : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Modifier : depth0)) != null ? stack1.Guard : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Modifier : depth0)) != null ? stack1.Absorb : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Modifier : depth0)) != null ? stack1.L5D : stack1),{"name":"if","hash":{"includeZero":true},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Modifier : depth0)) != null ? stack1.Duel : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n			</div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "					<i class=\"h-icon h-icon--reroll\"></i>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "					<i class=\"h-icon h-icon--crit\"></i>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "					<i class=\"h-icon h-icon--guard\"></i>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "					<i class=\"h-icon h-icon--absorb\"></i>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "					<i class=\"h-icon h-icon--death "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.Modifier : depth0)) != null ? stack1.L5D : stack1),true,{"name":"ifCond","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"></i>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "-color-greyed";
},"13":function(container,depth0,helpers,partials,data) {
    return "					<i class=\"h-icon h-icon--duel\"></i>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "\n			<div class=\"pm-battle-attack__dmg-ele  h-ele--"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.Data : depth0)) != null ? stack1.Element : stack1), depth0))
    + "\">\n				<i class=\"h-icon  a-ele-icon\"></i>\n				<span>"
    + alias1(((helper = (helper = helpers.Element || (depth0 != null ? depth0.Element : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Element","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper;

  return "\n			<div class=\"pm-battle-attack__dmg-penalty\">\n				<i class=\"h-icon h-icon--penalty\"></i>\n				<span>"
    + container.escapeExpression(((helper = (helper = helpers.Penalty || (depth0 != null ? depth0.Penalty : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Penalty","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper;

  return "\n			<div class=\"pm-battle-attack__dmg-atk\">\n				<i class=\"h-icon h-icon--up\"></i>\n				<span>"
    + container.escapeExpression(((helper = (helper = helpers.Atk || (depth0 != null ? depth0.Atk : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Atk","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"pm-battle-attack__blood -blood-"
    + alias4(((helper = (helper = helpers.BG || (depth0 != null ? depth0.BG : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"BG","hash":{},"data":data}) : helper)))
    + "\">\n\n	<div class=\"pm-battle-attack__dmg\">\n\n		<div class=\"pm-battle-attack__dmg-original\">\n			<span>"
    + alias4(((helper = (helper = helpers.Original || (depth0 != null ? depth0.Original : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Original","hash":{},"data":data}) : helper)))
    + "</span>\n		</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Modifier : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Element : depth0),{"name":"if","hash":{"includeZero":true},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Penalty : depth0),{"name":"if","hash":{"includeZero":true},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Atk : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n		<div class=\"pm-battle-attack__dmg-final  h-text-shadow--4\">"
    + alias4(((helper = (helper = helpers.Final || (depth0 != null ? depth0.Final : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Final","hash":{},"data":data}) : helper)))
    + "</div>\n\n	</div>\n\n</div>";
},"useData":true});
templates['battle/dialogue-box'] = template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "<div class=\"pm-battle-dialogue__row -side-"
    + container.escapeExpression(((helper = (helper = helpers.Align || (depth0 != null ? depth0.Align : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Align","hash":{},"data":data}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = helpers.Text || (depth0 != null ? depth0.Text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Text","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>";
},"useData":true});
templates['battle/effect-focus'] = template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<div class=\"c-effect -type-focus\">\n	<div class=\"c-effect__icon\"></div>\n	<div class=\"c-effect__text\"><b>"
    + container.escapeExpression(((helper = (helper = helpers.PercentNew || (depth0 != null ? depth0.PercentNew : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"PercentNew","hash":{},"data":data}) : helper)))
    + "</b>%</div>\n</div>";
},"useData":true});
templates['battle/effect-poison'] = template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"c-effect -type-poison\">\n	<div class=\"c-effect__icon\"></div>\n	<div class=\"c-effect__text\"><b>"
    + alias4(((helper = (helper = helpers.Turns || (depth0 != null ? depth0.Turns : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Turns","hash":{},"data":data}) : helper)))
    + "</b>/<em>"
    + alias4(((helper = (helper = helpers.TurnsMax || (depth0 != null ? depth0.TurnsMax : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TurnsMax","hash":{},"data":data}) : helper)))
    + "</em></div>\n</div>";
},"useData":true});
templates['battle/genjutsu-trigger'] = template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "-type-proc -proc-"
    + container.escapeExpression(((helper = (helper = helpers.ProcsTimes || (depth0 != null ? depth0.ProcsTimes : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ProcsTimes","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "-type-pass";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.PassAlong : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return "			<div class=\"c-effect__pass-icon  h-pass-genjutsu--"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\"></div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return "		<div class=\"c-effect__pass-icon  h-pass-genjutsu--"
    + container.escapeExpression(((helper = (helper = helpers.Receiver || (depth0 != null ? depth0.Receiver : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Receiver","hash":{},"data":data}) : helper)))
    + "\"></div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "		<div class=\"c-effect__box\">"
    + ((stack1 = ((helper = (helper = helpers.Multi || (depth0 != null ? depth0.Multi : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Multi","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "		<div class=\"c-effect__box\">"
    + ((stack1 = ((helper = (helper = helpers.ProcsText || (depth0 != null ? depth0.ProcsText : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ProcsText","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "		<div class=\"c-effect__box\">"
    + ((stack1 = ((helper = (helper = helpers.Learned || (depth0 != null ? depth0.Learned : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Learned","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "		<div class=\"c-effect__box\">"
    + ((stack1 = ((helper = (helper = helpers.Absorbed || (depth0 != null ? depth0.Absorbed : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Absorbed","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"c-effect -type-genjutsu "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.ProcsTimes : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Receiver : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-id=\""
    + alias4(((helper = (helper = helpers.ID || (depth0 != null ? depth0.ID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ID","hash":{},"data":data}) : helper)))
    + "\">\n\n	<div class=\"c-effect__cost\">"
    + alias4(((helper = (helper = helpers.Cost || (depth0 != null ? depth0.Cost : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Cost","hash":{},"data":data}) : helper)))
    + "</div>\n	<div class=\"c-effect__icon\"></div>\n	<div class=\"c-effect__text\">"
    + ((stack1 = ((helper = (helper = helpers.Text || (depth0 != null ? depth0.Text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Text","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.PassAlong : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Receiver : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Multi : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.ProcsText : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Learned : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Absorbed : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});
templates['forge/anvil-ingredient-box'] = template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return ((stack1 = ((helper = (helper = helpers.Itembox || (depth0 != null ? depth0.Itembox : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Itembox","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n\n<div class=\"p-forge-anvil-ingredient__amount  js-amount-required\">\n	<span>"
    + ((stack1 = ((helper = (helper = helpers.AmountText || (depth0 != null ? depth0.AmountText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"AmountText","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n</div>";
},"useData":true});
templates['general/error-box'] = template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "<div class=\"c-overlay-message__inner\">\n\n	<div class=\"c-overlay-message__text\">"
    + ((stack1 = ((helper = (helper = helpers.Text || (depth0 != null ? depth0.Text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Text","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n\n	<div class=\"c-overlay-message__buttons\">\n\n		<div class=\"c-button -color-no -width-auto\" data-defer=\"false\">\n			<span>"
    + container.escapeExpression(((helper = (helper = helpers.CloseText || (depth0 != null ? depth0.CloseText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CloseText","hash":{},"data":data}) : helper)))
    + "</span>\n		</div>\n\n	</div>\n\n</div>";
},"useData":true});
templates['general/level-up-bloodline'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "?Options="
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.BloodlineData : depth0)) != null ? stack1.Options : stack1), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "<div class=\"c-levelup -c-bloodline\">\n\n	<div class=\"c-levelup__bg\"></div>\n\n	<div class=\"c-levelup__inner\">\n\n		<div class=\"c-levelup__lvlup\">"
    + alias4(((helper = (helper = helpers.Title || (depth0 != null ? depth0.Title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data}) : helper)))
    + "</div>\n\n		<div class=\"c-levelup__text\">\n			<span>"
    + ((stack1 = ((helper = (helper = helpers.Desc || (depth0 != null ? depth0.Desc : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Desc","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n		</div>\n\n		<div class=\"c-levelup__text -margin-bot\">\n\n			<div class=\"c-item -size-l  "
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.BloodlineData : depth0)) != null ? stack1.Color : stack1), depth0))
    + " -border-colored  js-item-tooltip\" data-url=\"/tooltip/bloodline/"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.BloodlineData : depth0)) != null ? stack1.Identifier : stack1), depth0))
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.BloodlineData : depth0)) != null ? stack1.Options : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n				<div class=\"c-item__icon  a-item-icon\"></div>\n				<div class=\"c-item__name  a-item-name\">\n					"
    + ((stack1 = alias5(((stack1 = (depth0 != null ? depth0.BloodlineData : depth0)) != null ? stack1.Name : stack1), depth0)) != null ? stack1 : "")
    + "\n				</div>\n			</div>\n\n		</div>\n\n	</div>\n\n	<div class=\"c-levelup__close\">\n		<i class=\"icon-close\"></i>\n	</div>\n\n</div>";
},"useData":true});
templates['general/level-up'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n			<div class=\"c-levelup-attributes\">\n\n				<div class=\"c-bar\">\n\n					<div class=\"c-bar__fill\" style=\"width: 0%;\">\n\n						<div class=\"c-levelup-attributes__gain  h-text-shadow--2  js-levelup-stat\">\n							+<b>0</b>\n						</div>\n\n					</div>\n\n					<div class=\"c-levelup-attributes__icon  h-icon h-icon--tai  h-text-shadow--2\"></div>\n					<div class=\"c-levelup-attributes__name  h-text-shadow--2\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.Attributes : depth0)) != null ? stack1.Tai : stack1)) != null ? stack1.Name : stack1), depth0))
    + "</div>\n\n				</div>\n\n				<div class=\"c-bar\">\n\n					<div class=\"c-bar__fill\" style=\"width: 0%;\">\n\n						<div class=\"c-levelup-attributes__gain  h-text-shadow--2  js-levelup-stat\">\n							+<b>0</b>\n						</div>\n\n					</div>\n\n					<div class=\"c-levelup-attributes__icon  h-icon h-icon--nin  h-text-shadow--2\"></div>\n					<div class=\"c-levelup-attributes__name  h-text-shadow--2\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.Attributes : depth0)) != null ? stack1.Nin : stack1)) != null ? stack1.Name : stack1), depth0))
    + "</div>\n\n				</div>\n\n				<div class=\"c-bar\">\n\n					<div class=\"c-bar__fill\" style=\"width: 0%;\">\n\n						<div class=\"c-levelup-attributes__gain  h-text-shadow--2  js-levelup-stat\">\n							+<b>0</b>\n						</div>\n\n					</div>\n\n					<div class=\"c-levelup-attributes__icon  h-icon h-icon--buki  h-text-shadow--2\"></div>\n					<div class=\"c-levelup-attributes__name  h-text-shadow--2\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.Attributes : depth0)) != null ? stack1.Buki : stack1)) != null ? stack1.Name : stack1), depth0))
    + "</div>\n\n				</div>\n\n				<div class=\"c-bar\">\n\n					<div class=\"c-bar__fill\" style=\"width: 0%;\">\n\n						<div class=\"c-levelup-attributes__gain  h-text-shadow--2  js-levelup-stat\">\n							+<b>0</b>\n						</div>\n\n					</div>\n\n					<div class=\"c-levelup-attributes__icon  h-icon h-icon--sta  h-text-shadow--2\"></div>\n					<div class=\"c-levelup-attributes__name  h-text-shadow--2\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.Attributes : depth0)) != null ? stack1.Sta : stack1)) != null ? stack1.Name : stack1), depth0))
    + "</div>\n\n				</div>\n\n				<div class=\"c-bar\">\n\n					<div class=\"c-bar__fill\" style=\"width: 0%;\">\n\n						<div class=\"c-levelup-attributes__gain  h-text-shadow--2  js-levelup-stat\">\n							+<b>0</b>\n						</div>\n\n					</div>\n\n					<div class=\"c-levelup-attributes__icon  h-icon h-icon--ele  h-text-shadow--2\"></div>\n					<div class=\"c-levelup-attributes__name  h-text-shadow--2\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.Attributes : depth0)) != null ? stack1.Ele : stack1)) != null ? stack1.Name : stack1), depth0))
    + "</div>\n\n				</div>\n\n				<div class=\"c-bar\">\n\n					<div class=\"c-bar__fill\" style=\"width: 0%;\">\n\n						<div class=\"c-levelup-attributes__gain  h-text-shadow--2  js-levelup-stat\">\n							+<b>0</b>\n						</div>\n\n					</div>\n\n					<div class=\"c-levelup-attributes__icon  h-icon h-icon--gen  h-text-shadow--2\"></div>\n					<div class=\"c-levelup-attributes__name  h-text-shadow--2\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.Attributes : depth0)) != null ? stack1.Gen : stack1)) != null ? stack1.Name : stack1), depth0))
    + "</div>\n\n				</div>\n\n			</div>\n\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n			<div class=\"c-levelup__text\">\n\n		        <span>"
    + ((stack1 = ((helper = (helper = helpers.SummonText || (depth0 != null ? depth0.SummonText : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"SummonText","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n\n			</div>\n\n			<div class=\"c-levelup__text\">\n\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.Stats : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n			</div>\n\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "\n					<span>\n						<b>"
    + alias2(alias1((depth0 != null ? depth0.Value : depth0), depth0))
    + "</b> "
    + alias2(alias1((depth0 != null ? depth0.Name : depth0), depth0))
    + "\n					</span>\n\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n			<div class=\"c-levelup__text  js-levelup-notification\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Notifications : depth0)) != null ? stack1.Seal : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Notifications : depth0)) != null ? stack1.Bloodline : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n			</div>\n\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "					<span>\n						"
    + container.escapeExpression((helpers.replace || (depth0 && depth0.replace) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.Notifications : depth0)) != null ? stack1.Seal : stack1)) != null ? stack1.Text : stack1),{"name":"replace","hash":{"0":"<i class=\"icon-seal\"></i>"},"data":data}))
    + "\n					</span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "					<span>\n						"
    + container.escapeExpression((helpers.replace || (depth0 && depth0.replace) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.Notifications : depth0)) != null ? stack1.Bloodline : stack1)) != null ? stack1.Text : stack1),{"name":"replace","hash":{"0":"<i class=\"icon-sharingan\"></i>"},"data":data}))
    + "\n					</span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"c-levelup\">\n\n	<div class=\"c-levelup__bg\">\n		<img src=\""
    + alias4(((helper = (helper = helpers.Background || (depth0 != null ? depth0.Background : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Background","hash":{},"data":data}) : helper)))
    + "\">\n	</div>\n\n	<div class=\"c-levelup__inner\">\n\n		<div class=\"c-levelup__lvlup\">"
    + alias4(((helper = (helper = helpers.Title || (depth0 != null ? depth0.Title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Title","hash":{},"data":data}) : helper)))
    + "</div>\n\n		<div class=\"c-levelup__text\">\n			<span>"
    + ((stack1 = ((helper = (helper = helpers.Desc || (depth0 != null ? depth0.Desc : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Desc","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n		</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Attributes : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Stats : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Notifications : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n	</div>\n\n	<div class=\"c-levelup__close\">\n		<i class=\"icon-close\"></i>\n	</div>\n\n</div>";
},"useData":true});
templates['general/summon-stats'] = template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "\n	<div class=\"c-stats-list-stat -stat-"
    + alias2(alias1((depth0 != null ? depth0.Identifier : depth0), depth0))
    + "\">\n		<div class=\"c-stats-list-stat__name\">"
    + alias2(alias1((depth0 != null ? depth0.Name : depth0), depth0))
    + "</div>\n		<div class=\"c-stats-list-stat__base \">"
    + alias2(alias1((depth0 != null ? depth0.Base : depth0), depth0))
    + "</div>\n		<div class=\"c-stats-list-stat__growth \">"
    + alias2(alias1((depth0 != null ? depth0.Growth : depth0), depth0))
    + "</div>\n\n		<div class=\"c-stats-list-stat__total\">"
    + alias2(alias1((depth0 != null ? depth0.Total : depth0), depth0))
    + "</div>\n	</div>\n\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.Stats : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
templates['general/tooltip-loader-help'] = template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"c-help\">\n\n	<div class=\"c-help__outer\">\n\n		<div class=\"c-help__head\">\n			<span></span>\n		</div>\n\n		<div class=\"c-help__inner -c-loading\">\n\n			<div class=\"c-overlay -c-active -loader-spin\">\n				<div class=\"c-overlay__loader\"></div>\n			</div>\n\n		</div>\n\n	</div>\n\n</div>";
},"useData":true});
templates['general/tooltip-loader'] = template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"m-tooltip"
    + alias4(((helper = (helper = helpers.Class || (depth0 != null ? depth0.Class : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Class","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.Color || (depth0 != null ? depth0.Color : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Color","hash":{},"data":data}) : helper)))
    + "\">\n\n	<div class=\"m-tooltip"
    + alias4(((helper = (helper = helpers.Class || (depth0 != null ? depth0.Class : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Class","hash":{},"data":data}) : helper)))
    + "__outer\">\n\n		<div class=\"c-overlay -c-active -loader-spin\">\n			<div class=\"c-overlay__loader\"></div>\n		</div>\n\n	</div>\n\n</div>";
},"useData":true});
templates['kage/match-results'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "-c-win";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "\n	<div class=\"c-match-box-score__center\">\n		<div class=\"c-match-box-score__replay  js-replay\" data-replayid=\""
    + container.escapeExpression(((helper = (helper = helpers.Replay_ID || (depth0 != null ? depth0.Replay_ID : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Replay_ID","hash":{},"data":data}) : helper)))
    + "\"></div>\n	</div>\n\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.lambda, alias4=container.escapeExpression;

  return "<div class=\"c-match-box-score__score "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.WinnerSide : depth0),1,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + alias4(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.MatchInfo : depth0)) != null ? stack1.Teams : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.Defeated : stack1), depth0))
    + "</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Replay_ID : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"c-match-box-score__score "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.WinnerSide : depth0),2,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + alias4(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.MatchInfo : depth0)) != null ? stack1.Teams : stack1)) != null ? stack1["1"] : stack1)) != null ? stack1.Defeated : stack1), depth0))
    + "</div>";
},"useData":true});
templates['lineup/empty-card'] = template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"c-card__pos  c-pos -pos-"
    + alias4(((helper = (helper = helpers.Position || (depth0 != null ? depth0.Position : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Position","hash":{},"data":data}) : helper)))
    + "\"></div>\n<div class=\"c-card__pos-name\">"
    + alias4(((helper = (helper = helpers.PositionName || (depth0 != null ? depth0.PositionName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PositionName","hash":{},"data":data}) : helper)))
    + "</div>";
},"useData":true});
templates['modules/element-selection'] = template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"js-overlay-msg-selection\" data-selector=\"Element\" data-selected=\"1\">\n\n	<div class=\"c-overlay-message__text\">"
    + ((stack1 = ((helper = (helper = helpers.Text || (depth0 != null ? depth0.Text : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"Text","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n\n	<div class=\"c-overlay-message__elements\">\n		<div data-val=\"1\" class=\"c-ele -size-l -ele-1 -c-sel\"></div>\n		<div data-val=\"2\" class=\"c-ele -size-l -ele-2\"></div>\n		<div data-val=\"3\" class=\"c-ele -size-l -ele-3\"></div>\n		<div data-val=\"4\" class=\"c-ele -size-l -ele-4\"></div>\n		<div data-val=\"5\" class=\"c-ele -size-l -ele-5\"></div>\n	</div>\n\n</div>";
},"useData":true});
templates['myteam/confirm-box'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "\n        <div class=\"c-overlay-message__selection\">"
    + ((stack1 = ((helper = (helper = helpers.SelectionHtml || (depth0 != null ? depth0.SelectionHtml : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"SelectionHtml","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"c-overlay-message__inner\">\n\n    <div class=\"c-overlay-message__text\">"
    + ((stack1 = ((helper = (helper = helpers.Text || (depth0 != null ? depth0.Text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Text","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Selection : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n	<div class=\"c-overlay-message__buttons\">\n\n		<div class=\"c-button -color-yes -width-auto\" data-defer=\"true\">\n			<span>"
    + alias4(((helper = (helper = helpers.ConfirmText || (depth0 != null ? depth0.ConfirmText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ConfirmText","hash":{},"data":data}) : helper)))
    + "</span>\n		</div>\n\n		<div class=\"c-button -color-no -width-auto\" data-defer=\"false\">\n			<span>"
    + alias4(((helper = (helper = helpers.CancelText || (depth0 != null ? depth0.CancelText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CancelText","hash":{},"data":data}) : helper)))
    + "</span>\n		</div>\n\n	</div>\n\n</div>";
},"useData":true});
templates['myteam/empty-item'] = template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"c-item -size-l  h-item-disabled-2\">\n	<div class=\"c-item__icon  a-item-icon\"></div>\n</div>";
},"useData":true});
templates['myteam/sockets-genjutsu'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n    <div class=\"m-tooltip-genjutsu "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Removed : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.EmptySocket : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-genjutsuid=\""
    + alias4(((helper = (helper = helpers.ID || (depth0 != null ? depth0.ID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ID","hash":{},"data":data}) : helper)))
    + "\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.RemoveText : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <div class=\"m-tooltip-genjutsu__inner\">\n\n            <div class=\"m-tooltip-genjutsu__top\">\n                <div class=\"m-tooltip-genjutsu__cost\">"
    + alias4(((helper = (helper = helpers.Capacity || (depth0 != null ? depth0.Capacity : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Capacity","hash":{},"data":data}) : helper)))
    + "</div>\n                <div class=\"m-tooltip-genjutsu__icon\"></div>\n                <div class=\"m-tooltip-genjutsu__name  js-socket-genjutsu-name\">"
    + ((stack1 = ((helper = (helper = helpers.Name || (depth0 != null ? depth0.Name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Name","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Socket : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n    </div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "-status-disabled";
},"4":function(container,depth0,helpers,partials,data) {
    return "-c-empty";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n		    <div class=\"c-button -color-no -size-s -text-center\" data-sockettype=\""
    + alias4(((helper = (helper = helpers.Socket || (depth0 != null ? depth0.Socket : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Socket","hash":{},"data":data}) : helper)))
    + "\" data-socketindex=\""
    + alias4(((helper = (helper = helpers.Index || (depth0 != null ? depth0.Index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Index","hash":{},"data":data}) : helper)))
    + "\">\n			    <span>"
    + ((stack1 = ((helper = (helper = helpers.RemoveText || (depth0 != null ? depth0.RemoveText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RemoveText","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n		    </div>\n\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "\n		            <div class=\"m-tooltip-genjutsu__socket  js-socket\" data-capacity=\""
    + container.escapeExpression(((helper = (helper = helpers.Socket || (depth0 != null ? depth0.Socket : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Socket","hash":{},"data":data}) : helper)))
    + "\">\n			            <span>"
    + ((stack1 = ((helper = (helper = helpers.SocketText || (depth0 != null ? depth0.SocketText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SocketText","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n		            </div>\n\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
})();